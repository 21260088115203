export const ArticleBanner =
  'Gpay like mobile application, money remittance platform';
export const Article1B1a = 'Online crypto currency platform and money exchange';
export const Article1B1b = 'Ecommerce platforms accepting crypto payments';
export const Article1B1c = 'Mobile payments done via digital bank card';

export const Article2Banner =
  'Bitcoin trading mobile application and exchange platform';
export const Article1B2a = 'Money remittance mobile application development';
export const Article1B2b = 'Software developers and software platforms';
export const Article1B2c = 'Create a custom crypto trading bot ';

export const Article3Banner = 'digital development in the agriculture sector';
export const Article1B3a = 'mobile application detecting diseases';
export const Article1B3b = 'online techniques for educating farmers';

export const Article4Banner =
  'creating an online business via ecommerce store ';
export const Article4B4a = 'custom online store elementor on wordpress';
export const Article4B4b = 'custom online store via shopify';
export const Article4B4c =
  'get software developers to build an ecommerce store';

export const Article5Banner =
  'product forecasting using ai & ml for prediction';
export const Article5B5a =
  'Enhance product recommendations via AI & ML algorithms';
export const Article5B5b = 'make data driven decisions via AI & ML platforms';
export const Article5B5c = 'using python to create ai & ml applications';

export const Article6Banner =
  'digital development and educational technology via app development';
export const Article6B6a = 'Geofencing and cab hailing mobile applications';
export const Article6B6b = 'Web 3 and advancements in educational industry ';
export const Article6B6c = 'building a custom learning management system ';

export const Article7Banner = 'adaptive learning courses and deep learning';
export const Article7B7a =
  'advancements in education section & machine learning';
export const Article7B7b =
  "data driven decisions enhancing student's advancements";

export const Article8Banner =
  'custom application and platform development for digital development';
export const Article8B8a = 'educational technology and digital development';
export const Article8B8b = 'from paper work to the digital work';
export const Article8B8c = 'wireframes and custom mobile app developement';

export const Article9Banner =
  'automating interview scheduling and digital development';
export const Article9B9a =
  'efficient interview scheduling via Calendly like application';
export const Article9B9b = 'increase efficiency via custom app development';
export const Article9B9c =
  'automating human resoures processes via digital development ';

export const Article10Banner = 'automate HR via custom app development';
export const Article10B10a = 'develop custom ai HR app via phython ';
export const Article10B10b =
  'boosting productivity via machine learning application';

export const Article11Banner = 'create custom crm software for your busines';
export const Article11B11a = 'PHP and laravel built custom platforms ';
export const Article11B11b = 'ecommerce efficiency via advanced forecasting';

export const Article12Banner = 'custom fleet management platform and softwares';
export const Article12B12a =
  'artificial intelligence platforms in fleet management';
export const Article12B12b = 'Google Maps and how to build a cab business';
export const Article12B12c = 'advancements and future in fleet management';

export const Article13Banner = ' Digital Transformation in Middle East';
export const Article13B1a = 'develop custom PRM solutions';
export const Article13B1b = 'future of healthtech in middle east';

export const Article14Banner = 'multilingual mobile applications';
export const Article14B1a = 'user focused design ';
export const Article14B1b = 'designing for diversity';
export const Article14B1c = 'Embracing Cultural Sensitivity';

export const Article15Banner = 'Patient Relationship Management softwares';
export const Article15B1b = 'payment gateway integration';
export const Article15B1c = ' Automated Billing Solutions';

export const Article16Banner = 'Continuous Care in Mental Health';
export const Article16B1a = 'Messaging Support in mobile apps';
export const Article16B1b = 'online doctor consultation';
export const Article16B1c = 'Mental Health Technology';

export const Article31Banner = 'Health Education in Patient Care';
export const Article31B1a = 'Health Education';
export const Article31B1b = 'Leveraging Technology for Interactive Learning';

export const Article32Banner = 'Health Education in Patient Care';
export const Article32B1a = 'Health Education';
export const Article32B1b = 'Leveraging Technology for Interactive Learning';
export const Article32B1c = 'Leveraging Technology for Interactive Learning';
